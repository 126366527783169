/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_main.jpg").default + ")",
        }}
      ><div className="filter" />
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div style={{borderColor:"#000", marginTop:0,}}>
              <h2 style={{color:"#FFFFFF", fontWeight:600}}>Oferecemos soluções jurídicas estratégicas para prevenir e resolver conflitos ambientais</h2>    
            </div>

            <div style={{color:"#FFF"}}>
            <h3>
            É importante trabalhar com profissionais especializados em direito ambiental para garantir que os conflitos sejam tratados de maneira adequada e eficaz.
            </h3>
            </div><br />
            <Button
                className="btn-round"
                color="success"
                style={{backgroundColor:"#90D63D", borderWidth:0,}}
                href="https://api.whatsapp.com/send/?phone=5516991095555&text&type=phone_number&app_absent=0"
                target="_Blank"
                        >
                Fale com especialista
              </Button>
          </Container>
        </div>       
        <h6 className="cat3egory category-absolute" style={{textTransform:"none"}}>
        © 2023. L. Mesquita.
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
