import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import logo from "../../assets/img/logo.png";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li style={{ fontFamily:'Roboto Slab'}}>
           
                  <img style={{width:35}} src={logo} />  &nbsp;L. Mesquita
              
              </li>
              <li style={{ fontFamily:'Roboto Slab'}}>
       
        
                  (16) 99109-5555
             
              </li>
              <li style={{ fontFamily:'Roboto Slab'}}>
        
                  mesquitarp@icloud.com
           
              </li>
              <li style={{ fontFamily:'Roboto Slab'}}>
       
        
              Avenida Antônio Marçal, 205, Jardim Canadá, Ribeirão Preto - SP
  
   </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright" style={{ fontFamily:'Roboto Slab'}}>
              © {new Date().getFullYear()}.
            L. Mesquita
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
