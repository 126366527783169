import React from "react";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
  Card,

} from "reactstrap";

import img1 from "../../assets/img/img1.png";
import advogados from "../../assets/img/advogados.png";

import espe_01 from "../../assets/img/espe_01.jpg";
import espe_02 from "../../assets/img/espe_02.jpg";
import espe_03 from "../../assets/img/espe_03.jpg";
import espe_04 from "../../assets/img/espe_04.jpg";
import espe_05 from "../../assets/img/espe_05.jpg";
import espe_06 from "../../assets/img/espe_06.jpg";
import espe_07 from "../../assets/img/espe_07.jpg";
import espe_08 from "../../assets/img/espe_08.jpg";
import espe_09 from "../../assets/img/espe_09.jpg";
import espe_10 from "../../assets/img/espe_10.jpg";
import espe_11 from "../../assets/img/espe_11.jpg";
import espe_12 from "../../assets/img/espe_12.jpg";
import espe_13 from "../../assets/img/espe_13.jpg";
import espe_14 from "../../assets/img/espe_14.jpg";
import espe_15 from "../../assets/img/espe_15.jpg";

function SectionButtons() {
  React.useEffect(() => {
    if (
      !document
      
    ) {
      
    }
    
  });
  return (
    <>
    <div id="direito">
    <Container>
          <div align="center" className="title">
            <h2 style={{fontWeight:400,}}>Nos últimos anos, anulamos ou <br />suspendemos a cobrança de mais de</h2>
            <h1 style={{fontWeight:600, color:"#90D63D", marginTop:-5}}>R$16.000.000,00</h1>
            <h2 style={{fontWeight:400, marginTop:-5}}>em multas ambientais.</h2>
          </div>
          <hr />
    </Container>
  
    </div>
    <div >
    <Container>
   
          <Row>
            <Col sm style={{fontSize:16, fontWeight:400, fontFamily:'Roboto Slab',}}>
            <div align="left" className="title">
            <h2 style={{fontSize:20, fontWeight:600, fontFamily:'Roboto Slab',}}>DIREITO AMBIENTAL E DO AGRONEGÓCIO</h2>
            </div>

            O Escritório L. Mesquita possui especialização e mais de 15 anos de experiência para atender a todas as demandas relacionadas ao Direito Público, para a defesa do empresário do agronegócio e da sua empresa ou propriedade, tanto na esfera administrativa quanto na esfera judicial.<br /><br />

            Preocupado com os anseios atuais do produtor rural, o Escritório também se especializou na busca de ferramentas jurídicas hábeis à proteção patrimonial, planejamento sucessório e economia tributária, de forma a apresentar um leque de opções que melhor atenta aos objetivos de cada produtor, sempre com vista à proteção do patrimônio conquistado. <br /><br />

Desde o planejamento dos riscos vinculados aos impactos do empreendimento, passando pelo compliance e pela mitigação de riscos e danos, até regularização de propriedades, com ênfase em ação civil pública e suspensão e anulação de multas e autos de infração, o escritório de advocacia L. Mesquita conta com uma equipe multidisciplinar para assessoramento de demandas vinculadas aos impactos ao meio ambiente.
            </Col>
            <Col sm style={{textAlign:"center"}}>
            <img src={img1} style={{width:"80%"}} />
            </Col>
            </Row><br />&nbsp;
            <Button id="especialidades"
                className="btn-round"
                color="success"
                style={{backgroundColor:"#90D63D", borderWidth:0,}}
                href="https://api.whatsapp.com/send/?phone=5516991095555&text&type=phone_number&app_absent=0" target="_Blank">
                Fale com especialista
              </Button>
              <br />&nbsp;
            <hr />
    </Container>
    </div>
    
    <div  >
      
    <Container>
    <div align="left" className="title">
            <h2 style={{fontSize:20, fontWeight:600,}}>ESPECIALIDADES</h2>
            </div>
      <Row>
      <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_01} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Ação Civil Pública Ambiental
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_02} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Suspensão e Anulação de Multas e Autos de Infração
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_03} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Inquérito Civil
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_04} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Incêndios em Propriedades Rurais
          </div>
        </Card>
        </Col>
      </Row>



      <Row>
      <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_05} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Regularização de intervenção em APP em área consolidada
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_06} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Regularização de área de Reserva Legal
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_07} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Processo Administrativo Ambiental
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_08} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Cadastro Ambiental Rural – CAR
          </div>
        </Card>
        </Col>
      </Row>


      <Row>
      <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_09} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Licença Ambiental
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_10} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Crime contra o Meio Ambiente
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_11} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Termo de Ajustamento de Conduta
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_12} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Responsabilização por Dano Ambiental
          </div>
        </Card>
        </Col>
      </Row>



      <Row>
      <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_13} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>         
          Compliance Ambiental
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_14} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>     
Holding Familiar
          </div>
        </Card>
        </Col>
    
        <Col sm style={{textAlign:"center"}}>
        <Card>
          <div style={{padding:15, fontWeight:400,}}>
          <img src={espe_15} />
          </div>
          <div style={{padding:15, fontWeight:600, fontFamily:'Roboto Slab'}}>     
          Planejamento Patrimonial e Sucessório
          </div>
        </Card>
        </Col>
        <Col sm style={{textAlign:"center"}}>
        
        </Col>
      </Row>


      <br />&nbsp;
            <hr />
    </Container>
    </div>


    <div id="advogados">
    <Container>
   
          <Row>
            
          <Col sm style={{textAlign:"center"}}>
            <img src={advogados} style={{width:"80%"}} />
            </Col>
            <Col sm style={{fontSize:16, fontWeight:400, fontFamily:'Roboto Slab'}}>
            <div align="left" className="title">
            <h2 style={{fontSize:20, fontWeight:600,}}>Possui algum problema jurídico?</h2>
            </div>

            <h2 style={{fontSize:20, fontWeight:600,}}>Lucas Mesquita<br />
OAB/SP nº 268.095</h2><br /><br />

Mestre em Direito pela Universidade de Ribeirão Preto, professor universitário, atua há mais de 15 anos, especificamente na área do Direto Público voltado à defesa do empresário do Agronegócio.<br /><br />

Nos últimos anos, anulamos ou suspendemos a cobrança de mais de R$16.000.000,00 em multas ambientais.
            </Col>
            </Row>
            <div style={{ textalAlign:"right", marginTop:40,}}>
            <Button
            align="right"
                className="btn-round"
                color="success"
                style={{backgroundColor:"#90D63D", borderWidth:0,}}
                href="https://api.whatsapp.com/send/?phone=5516991095555&text&type=phone_number&app_absent=0" target="_Blank">
                Fale com especialista
              </Button></div>
              <br />&nbsp;
            <hr />
    </Container>
    </div>


     
    </>
  );
}

export default SectionButtons;
